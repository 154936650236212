export const palette = {
  alternate: {
    main: '#F9F6FF',
    light: '#F9F8F8',
    dark: '#E5EAF4',
  },
  cardShadow: 'rgba(23, 70, 161, .11)',
  type: 'light',
  primary: {
    main: '#CDE1CB',
    contrastText: '#FFFFFF',
    light: 'rgba(209, 228, 221, 0.4)',
  },
  secondary: {
    main: '#253746',
    contrastText: '#668E4D',
  },
  text: {
    primary: '#212930',
    primaryLight: '#697883',
    light: '#616F83',
    secondary: '#5A6D8E',
    description: '#000000',
    disabled: '#CCC',
    highlight: '#FFF',
    success: '#1CA179',
    border: '#E5EAF4',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  borderColor: '#BCE194',
  background: {
    main: '#BCE194',
    dark: '#80A868',
    header: '#253746',
    light: '#E6F0E5',
    description: '#D1E4DD',
    paper: '#FFF',
    default: '#FFF',
    footer: '#BCE194',
    level2: '#f7f8fb',
    level1: '#FFF',
    tableHead: '#222930',
    tableSubhead: '#F7F7F7',
    tableAlternate: '#F8F8F8',
    alternate: '#E3F1FD',
    success: '#DBEFDC',
    error: '#FFEAEA',
    secondary: '#222930',
    primary: '#253746',
    primaryLight: '#d1e4dd',
    staticFooter: '#EEF2F6'
  },
  verticalBar: {
    used: '#6AC64B',
    unused: 'rgba(209, 228, 221, 0.4)',
    unlimited: '#C0C0C0',
  }
};
