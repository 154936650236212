import QuoteLeft from 'assets/images/quote-left.svg';

import YesIcon from 'assets/images/yes-icon.svg';

import SuccessImage from 'assets/images/create-success.svg';
import CancelRenewalImage from 'assets/images/cancel-renewal-v2.svg';
import CanceSubslImage from 'assets/images/sad-smiley.svg';
import ThumbsupImage from 'assets/images/thumbsup-smiley.svg';

import Logo from 'assets/images/logo.svg';
import Theme from 'theme';

const {
  REACT_APP_GROUP_ID: groupId,
  REACT_APP_CLIENT_HEADER: clientHeader = groupId,
} = process.env;

const config = {
  groupId,
  logo: Logo,
  cptCodesIcon: YesIcon,
  portalName: 'Ideal Dental',
  tokenName: 'idealdentalSubscriberToken',
  globalClass: 'idealdental-container',
  clientHeader,
  enableHeroButton: false,
  pricingType: 'standard-pricing',
  headerProps: {
    enable: false,
  },
  homeTemplateProps: {
    heroSection: {
      variant: 1,
      titleClasses: 'home-hero-title',
      subTitleClasses: 'home-hero-subtitle',
      secondaryButton: 'Login',
      secondaryButtonProps: {
        to: "/login"
      },
      heroImage: '/images/home-images/home-banner-img.webp',
      verticalAlignImage: 'bottom',
    },
    benefitsSection: {
      variant: 2,
      columns: 3,
      alignCard: 'left',
      alignTitle: 'left',
    },
    subscriptionSection: {
      tabVariant: 2,
      carouselResponsiveItems: {
        desktop: 3,
      },
      planCardProps: {
        buttonProps: {
          normalVariant: 'contained',
          highlightedVariant: "contained",
          customSpace: 12
        }
      }
    },
    howItWorksSection: {
      variant: 3,
      desktopHeroImg: '/images/home-images/how-it-works-bg.webp',
    },
    testimonialSection: {
      variant: 3,
      quoteImage: QuoteLeft,
      messageType: 'h6',
      gridSpacing: {
        xSmall: 12,
        medium: 12,
      },
      carouselResponsiveItem: {
        superLarge: 1,
        large: 1,
      },
    },
    staticHeroSection: {
      enable: true,
      enableStaticFooter: true,
      linkTo: '/employer',
      variant: 1,
    }
  },
  employer: {
    variant: 2,
    hubspotForm: {
      formId: "3568cefe-8c27-4e62-ac1e-1771d4baac5f",
      portalId: "40118398"
    },
    heroSection: {
      variant: 2,
      titleClasses: 'home-hero-title',
      subTitleClasses: 'home-hero-subtitle',
      showSubtitleDivider: true,
      primaryButtonProps: {
        to: '/find-a-provider',
      },
      secondaryButtonProps: {
        scrollTo: 'static-plan-cards',
        color: 'primary',
      },
      background: '/images/employer/employee-banner-img.webp',
    },
    benefitsSection: {
      variant: 6,
      columns: 3,
      customXS: 6,
      alignTitle: 'left',
      alignCard: 'left',
      sameLineIconText: 6,
    },
    metrics: {
      graphImg: '/images/employer/graph.webp',
    },
    subscriptionSection: {
      cardVariant: 5,
      tabVariant: 2,
      renderAsCardGrid: true,
      frequencyPlans: true,
      showOtherPlans: false,
      showPrimaryButton: true,
      offerText1Variant: 'body1',
      offerText2Link: '',
      offerText2Action: {
        type: 'popup',
        action: 'savings-summary',
      },
      gridModel: true,
      carouselResponsiveItems: {
        superLargeDesktop: 4,
        desktop: 4,
      },
      plansPerRow: {
        lg: 4,
      },
      showAddons: false,
      planCardProps: {
        cardGroup: true,
      }
    },
    comparison: {
      clientImg: '/images/employer/clientImg.webp',
      otherImg: '/images/employer/otherImg.webp',
    },
    howItWorksSection: {
      enable: true,
      variant: 1,
      heroImage: '/images/employer/how-it-works-employer.webp',
      primaryButtonProps: {
        variant: 'outlined',
        color: 'secondary',
      },
    },
  },
  checkoutSection: {},
  findProviderSection: {},
  choosePlanSection: {
    sectionSubtitle: '',
    locationLogo: Logo,
    cardVariant: 2,
    tabVariant: 2,
    tabLabel: {
      annual: 'Billed Annually',
      annual_billed_monthly: 'Billed Monthly'
    },
    planCardProps: {
      buttonProps: {
        normalColor: 'primary',
        highlightedColor: 'primary',
        normalVariant: "contained",
        highlightedVariant: "outlined",
      }
    },
    staticHeroSection: {
      enable: true,
      variant: 1,
    },
  },
  subscriptionSuccess: {
    successImage: SuccessImage,
    enableBookAppointment: false,
  },
  savingsSummary: {
    enableFooterbar: true,
    showCategoryHeaders: true,
    contactSection: 'contactDetails',
    moreSavingsButtonProps: {
      variant: 'contained',
      color: 'primary'
    },
  },
  faqSection: {
    heroProps: {
      secondaryButtonProps: {
        variant: 'outlined',
        color: 'primary',
      },
    },
  },
  dashboardProps: {
    showBenefitsUsage: true,
    cancelRenewal: {
      image: CancelRenewalImage,
    },
    cancelSubscription: {
      image: CanceSubslImage,
    },
    reactivateRenewal: {
      image: ThumbsupImage,
    },
  },
  footer: {
    enable: false,
  },
  loginPage: {
    bannerImage: './images/login.webp',
  },
  theme: Theme
};

export default config;
