import { palette } from './palette';
import background from 'assets/images/faq-banner-img.webp';
import contactUsBg from 'assets/images/contactUs-bg.webp';
import savingsBg from 'assets/images/savings-banner-img.webp';
import employerBg from 'assets/images/employee-banner-img.webp';

export const homeStyle = {
  '.drawer-wrapper': {
    '& .drawer-title-container, .drawer-close-button': {
      background: palette.text.primary,
      color: palette.text.highlight,
    },
    '& .theme-highlight-color': {
      fontWeight: 'bold',
      color: palette.text.secondary
    }
  },
  '& .custom-drawer-wrapper': {
    '& .drawer-title-container': {
      background: palette.text.primary
    },
    '& .customPromoToggle': {
      color: palette.text.secondary
    }
  },
  '.popup-action-button': {
    color: palette.primary.contrastText,
  },

  /* Login - Styles */
  '.login-root': {
    '& .hero-shaped__wrapper': {
      '& .hero-shaped__left-side': {
        flex: '0 0 60%',
        maxWidth: '60%',

        '@media(min-width: 900px)': {
          flex: '0 0 50%',
          maxWidth: '50%',
        },

        '& .hero-shaped__image': {
          width: '60vw',

          '@media(min-width: 900px)': {
            width: '50vw',
          },
        },
      },
    },
  },

  '& .header-wrapper': {
    borderStyle: 'solid',
    borderWidth: '0px 0px 8px 0',
    borderColor: palette.borderColor,
  },

  '.idealdental-container': {
    /* App main - styles */
    '& .app-main-content': {
      paddingTop: 0,
      '& .app-main-box': {
        background: palette.text.highlight,
      },
    },

    '& .savings-hero-section, .checkout-hero-section, .plan-selection-section, .dashboard-hero-section, .find-provider-section, & .terms-hero-section, & .privacy-hero-section, & .hipaa-hero-section':
      {
        background: palette.background.header,
        '& .hero-header-title, .hero-header-subtitle': {
          color: palette.text.highlight,
        },
      },

    '& .savings-hero-section': {
      height: 360,
      backgroundColor: 'unset',
      background: `url(${savingsBg}) no-repeat center`,
      backgroundSize: 'cover',
    },

    '& .link': {
      color: palette.text.primary,
      fontSize: '20px',
    },

    /* Hero Section - Styles */
    '& .home-hero-wrapper': {
      '@media(min-width: 900px)': {
        backgroundImage: 'linear-gradient(90deg, #FFFFFF 36%, #f5f9f8 0%)',
      },

      '& .hero-section-alternate': {
        height: 'auto !important',
        paddingBottom: 0,

        '& .hero-title': {
          maxWidth: 600,
          lineHeight: '55px',

          '@media(min-width: 900px)': {
            lineHeight: '80px',
          },
        },
        '& .hero-subtitle': {
          maxWidth: 500,
          fontSize: 28,
          color: palette.text.primary
        },
      },
      '@media(max-width: 900px)': {
        '& .section-alternate__content': {
          background: palette.text.highlight,
        },
      },
      '& .image-wrapper': {
        '@media(max-width: 900px)': {
          backgroundImage: 'linear-gradient(90deg, #FFFFFF 16%, #f5f9f8 0%)',
          padding: '24px 48px 24px 16px',
        },
        '& .image': {
          boxShadow: 'none',
          height: 'auto',
        },
      },
    },

    '& .section-alternate__content': {
      padding: '72px 16px',
      '@media(max-width: 900px)': { 
        padding: '42px 16px',
      }
    },

    '& .footer-wrapper': {
      background: palette.background.description,
      color: palette.text.primary,
      '& .footer-link, .copyrights-footer-link': {
        color: palette.text.primary,
      },
      '& .copyrights-footer': {
        borderTop: `1px solid ${palette.divider}`,
      },
    },

    /* Benefits - Styles */
    '& .definition-Wrapper': {
      background: palette.text.highlight,
      '& .definition-variant-2': {
        padding: '0px 20px !important',
        '& .section-alternate__content': {
          padding: '48px 48px !important',
        },
        '@media(max-width: 900px)': {
          '& .section-alternate__content': {
            padding: '0px 0px !important',
          }
        },
      },
      '@media(max-width: 900px)': {
        '& .definition-variant-2': { 
          paddingTop: 10
        }
      },
      '& .section-header__title, .section-header__subtitle': {
        color: palette.text.primary,
      },
      '& .benefits-wrapper': {
        borderTop: `1px solid ${palette.text.light}`,
        paddingTop: 48,

        '@media(min-width: 900px)': {
          paddingTop: 48,
        },
      },
      '& #definition-section': {
        '@media(max-width: 900px)': {
          '& .section-header__subtitle': {
            fontSize: '16px',
          },
        },

        '& .section-header': {
          '@media(min-width: 900px)': {
            paddingBottom: 10,
          },
        },

        '& .definition-service-tile': {
          padding: (32, 32, 16),

          '& .definition-service-innertile': {
            '@media(min-width: 900px)': {
              '& .definition-service-title': {
                color: palette.text.primary,
                fontSize: '24px',
                fontWeight: 'bold',
              },
            },
            '& .definition-service-description': {
              fontSize: '16px',
              color: palette.text.primaryLight,
              paddingBottom: 48,
              borderBottom: `1px solid ${palette.text.primary}`,
            },
          },
        },
      },
    },

    /* Pricing & Benefits / Subscription - Styles */
    '& .subscription-wrapper': {
      background: palette.background.secondary,
      '& .subscription-bg': {
        position: 'unset',
        height: 576,
        background: `url('./images/home-images/pricing-benefits.webp') no-repeat center`,
        backgroundSize: 'cover',
      },
      '@media screen and (max-width: 768px)': {
        '& .summary-text': {
          fontSize: '16px',
          marginTop: '0px'
        },
      },
      '& #pricing-benefit-section': {
        '& .section-header__title, .section-header__subtitle, .subscription-tabs-label, .summary-text, .summary-subtext':
          {
            color: palette.text.highlight,
          },
        '& .planCard': {
          border: 'none',
          '&:hover': {
            background: palette.text.highlight,
            border: `1px solid ${palette.primary.main}`,
            color: palette.text.primary,
          },
          '& .highlight-divider': {
            backgroundColor: palette.text.disabled,
          },
          '& .customBenefitsContainer': {
            '& .bold': {
              fontWeight: 'bold',
            },
          },
        },
        '& .select-plan-button': {
          '&:hover': {
            background: `${palette.primary.main} !important`,
          },
        },

        '& .subscription-section': {
          padding: '50px 80px 50px',
          '& .section-header': {
            maxWidth: 720,

            '@media(min-width: 900px)': {
              margin: '0px auto 32px',
            },
          },
        },
        '@media(max-width: 900px)': {
          '& .subscription-section': { 
            padding: '50px 30px 50px',
            '& .section-header': { 
              marginBottom: '0px'
            }
          }
        },
        '& .savings-summary-link': {
          color: palette.primary.main,
          textDecoration: 'underline',
          cursor: 'pointer',
        },
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: palette.background.dark,
      },
      '& .MuiSwitch-track': {
        borderColor: palette.text.highlight,
      },
      '& .MuiSwitch-colorSecondary.Mui-checked': {
        color: palette.text.highlight,
        borderColor: palette.background.primary,
        '& .MuiSwitch-thumb': {
          borderColor: palette.primary.main,
          backgroundColor: palette.background.dark,
        },
      },
      '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track ': {
        borderColor: palette.primary.main,
        backgroundColor: `${palette.text.highlight} !important`,
      },
      '& .react-multi-carousel-dot--active button': {
        backgroundColor: palette.text.highlight,
      },
    },

    '& .savings-contact-card': {
      background: palette.background.light,
      '& .contact-title': {
        paddingBottom: '10px',
        fontSize: '38px',
      },
      '& .contact-subtitle': {
        paddingBottom: '10px',
        fontSize: '19px',
      },
      '& .primary-contact-button, .secondary-contact-button': {
        height: '62px',
        width: '210px !important',
        fontSize: '16px',
        border: `2px solid ${palette.text.highlight} !important`,
        '& .MuiButton-endIcon': {
          display: 'none',
        },
      },
      '& .primary-contact-button': {
        marginRight: '50px',
      },
      '@media(max-width: 900px)': {
        '& .primary-contact-button': {
          marginRight: '0px',
          marginBottom: '40px',
        },
        '& .section-header__cta-container': {
          display: 'flex',
          flexDirection: 'column',
        },
      },
    },

    /*Description Styling */
    '& .descriptionRootContainer': {
      borderBottom: `13px solid ${palette.text.primary}`,
      '& .customDesktopGridImg': {
        marginTop: -70,
      },
      '@media(max-width: 900px)': {
        background: 'none',
        '& .how-cover-image': {
          borderBottom: `13px solid ${palette.text.primary}`,
        },
        '& .customDesktopGridImg': {
          marginTop: -40,
        },
      },
    },
    '& .customContentHeader': {
      fontSize: '38px',
      maxWidth: '600px',
    },
    '& .customInnerContentHeader': {
      fontWeight: 'bolder',
      fontSize: '20px',
      color: palette.text.description,
    },
    '& .customDescription': {
      fontWeight: 300,
    },

    '& .descriptionButton': {
      fontSize: '18px',
      height: '62px',
      width: '240px',
      border: `2px solid ${palette.text.highlight}`,
    },

    '& .customContentGrid': {
      marginTop: '42px',
    },

    '@media(max-width: 900px)': {
      '& .customOverlapImageContainer': {
        bottom: '36px',
      },
      '& .customContentGrid': {
        padding: '0px 30px !important',
        marginTop: '40px',
      },
      '& .customContentHeader': {
        marginTop: '0px !important',
      },
    },

    /* Testimonial - Styles */
    '& .testimonial-wrapper': {
      background: palette.background.main,
      '& .section-header__overline-wrapper': {
        color: palette.primary.main,
      },

      '& .testimonial-title-wrapper': {
        '& .section-header__title': {
          maxWidth: 500,
        },
      },
      '& .testimonial-name': {
        color: palette.text.primary,
      },
      '& .testimonial-container': {
        flexDirection: 'column-reverse',

        '@media(min-width: 900px)': {
          flexDirection: 'row-reverse',
        },
      },
      '& .testimonial-content-wrapper': {
        marginTop: 10,
        '@media(min-width: 900px)': {
          marginTop: -200,
        },

        '& .react-multi-carousel-track': {
          paddingTop: 60,
          '@media(min-width: 900px)': {
            paddingTop: 190,
          },
        },
        '& .react-multi-carousel-dot--active button': {
          backgroundColor: palette.text.highlight,
        },
        '& .testimonial-message': {
          color: palette.text.primary,
          fontWeight: 400,
        },
      },
      '& .image-wrapper': {
        display: 'flex',
        justifyContent: 'center',

        '@media(min-width: 900px)': {
          justifyContent: 'left',
        },

        '& .image': {
          height: '100%',
          width: 'auto',

          '@media(min-width: 900px)': {
            height: 414,
          },
        },
      },
    },

    /* Static hero banner */
    '& .home-static-hero-wrapper': {
      backgroundColor: palette.primary.main,
      '& .hero-static-bg': {
        backgroundColor: 'unset',
      },
      '& .hero-static-section': {
        '& .primary-static-button': {
          background: palette.text.highlight,
          color: palette.text.primary,
          borderRadius: 80
        },
        '& .section-header__title': {
          color: palette.text.primary,
          fontSize: 36
        },
      },
    },

    /* Savings Summary */
    '& .savings-wrapper': {
      '& .section-header': {
        marginBottom: '40px',
      },
      '& .customBackToHomeButton': {
        background: palette.primary.main
      },
      '& .hero-title': {
        fontSize: '65px',
      },
      '& .service-plan-table .savings-subheader': {
        backgroundColor: palette.text.highlight,
        fontWeight: 600,
      },
      '& .savings-topheader': {
        color: `${palette.text.highlight} !important`,
        fontWeight: '500',
      },
      '& .savings-contact-card': {
        padding: 0,
      },
      '@media (max-width: 900px)': {
        '& .section-header__title': {
          fontSize: '34px',
        },
        '& .section-header__subtitle': {
          fontSize: '18px',
        },
      },

      '& .howItWorksRoot': {
        '& .section-header': {
          marginBottom: '0 !important',
        },
        '& .section-header__title': {
          fontSize: '38px',
          color: palette.primary.main,
          marginBottom: '10px',
          fontWeight: 600,
        },
        '& .section-header__subtitle': {
          fontSize: '19px',
          marginBottom: '10px',
          color: `${palette.text.secondary} !important`,
        },
        '& .cover-image-wrapper': {
          padding: '15px',
        },
        '& .step-title': {
          fontWeight: 600,
          marginBottom: '20px',
          color: palette.text.primary,
        },
        '& .step-content': {
          marginBottom: '20px',
        },
      },
      '& .footer-bar-text': {
        fontSize: '19px',
        fontWeight: 400,
        padding: 24,
        textAlign: 'center',
      },
    },

    /*Contact Us styling */
    '& .contactUsRoot': {
      '& .home-hero-wrapper': {
        marginTop: '10px',
        backgroundImage: `url(${contactUsBg})`,
        backgroundColor: palette.background.light,
        backgroundPosition: 'center right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '62% auto',

        '& .contactHeaderTitle': {
          fontSize: '50px',
          fontWeight: 600,
          marginBottom: '20px',
        },
        '& .contactHeaderSubtitle': {
          fontWeight: 300,
          fontSize: '28px',
          marginBottom: '30px',
        },
        '& .customSubtitleItems': {
          fontSize: '18px',
        },
        '@media (max-width: 747px)': {
          backgroundSize: '110% auto',
          '& .customTitleInnerContainer': {
            marginTop: '220px',
          },
          '& .section-alternate__content': {
            paddingTop: 0,
          },
        },
        '& .section-header': {
          marginTop: 225,
        },
        '& .image-wrapper': {
          '& .image': {
            '@media (min-width: 900px)': {
              display: 'none',
            },
          },
        },

        '@media (max-width: 900px)': {
          '& .hero-section': {
            maxHeight: '100%',
          },
          '& .hero-left-section': {
            display: 'flex',
            zIndex: 1,
          },
          '& .section-header': {
            padding: '5px',
            marginTop: '-100px',
          },
          '& .section-header__title': {
            fontSize: '40px',
            fontWeight: 600,
          },
          '& .section-header__subtitle': {
            fontSize: '20px !important',
            fontWeight: '300 !important',
          },
          backgroundImage: 'none',
          marginTop: '50px',
          '& .image-wrapper': {
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 0,
            marginTop: '15px',
          },
        },
        '& .section-header__title-wrapper': {
          maxWidth: '500px',
        },
        '& .section-header__subtitle': {
          fontSize: '28px',
          fontWeight: 400,
          maxWidth: 550,
        },
        '& .customIcon': {
          marginRight: '12px',
        },
      },

      '& .faq-contact-header-section': {
        minHeight: '547px',
      },
    },

    /* How it works */
    '& .howitworks-section': {
      '& .section': {
        padding: '48px 64px',
        paddingBottom: '0px'
      },
      background: palette.text.highlight,
      '& .howitworks-wrapper': {
        paddingBottom: 48,
        borderBottom: `1px solid ${palette.divider}`,
        '& .howitworks-title': {
          marginBottom: '48px'
        }
      },
      '@media(max-width:900px)': {
        '& .section': {
          padding: '48px 24px',
          paddingBottom: '0px'
        },
        '& .howitworks-title': {
          marginBottom: '24px !important'
        }
      }
    },

    /* Static Page Hero Banners */
    '& .dashboard-hero-section, & .terms-hero-section, & .privacy-hero-section, & .hipaa-hero-section':
      {
        backgroundColor: palette.background.header,
      },
    '& .book-appointment-hero-section': {
      height: '100%',
      backgroundColor: palette.background.main,

      '& .MuiTypography-root': {
        color: palette.text.primary,
      },
    },

    /* Book Appointment */
    '& .morning-image, & .noon-image, & .evening-image': {
      fill: `${palette.primary.main} !important`,
    },

    /* Create Subscriber - Checkout flow container */
    '& .checkout-flow-container': {
      '& .find-provider-section, & .plan-selection-section, & .checkout-hero-section':
        {
          background: palette.background.header,
        },
      '& .planCard': {
        '&:hover': {
          color: palette.text.primary,
        },
      },
    },

    /* Find a Provider Style */
    '& .provider-home-button': {
      background: palette.primary.main
    },

    /* Create Subscriber - Choose plan */
    '& .plan-selection-container': {
      '& .home-static-hero-wrapper': {
        '& .hero-static-bg': {
          backgroundColor: palette.background.light,
        },
      },
      '& .plans-wrapper': {
        '& .highlight-button': {
          backgroundColor: palette.text.highlight,
          color: palette.text.primary,
          '&:hover': {
            color: palette.text.primary,
          },
        },
        '& .highlight-divider': {
          backgroundColor: palette.text.disabled,
        },
      },
      '& .section-header__title': {
        color: `${palette.text.primary} !important`,
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: palette.background.dark,
      },
      '& .MuiSwitch-track': {
        borderColor: palette.primary.main,
      },
      '& .MuiSwitch-colorSecondary.Mui-checked': {
        color: palette.text.highlight,
        borderColor: palette.background.primary,
        '& .MuiSwitch-thumb': {
          borderColor: palette.primary.main,
          backgroundColor: palette.background.dark,
        },
      },
      '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track ': {
        borderColor: palette.text.highlight,
        backgroundColor: `${palette.primary.main} !important`,
      },
      '& .customEditButton': {
        color: palette.text.primary
      },
      '& .customEditIcon': {
        stroke: palette.text.primary
      },
      '& .provider-details': {
        border: `1px solid ${palette.primary.main}`
      }
    },

    /* FAQ */
    '& .faq-hero-section': {
      height: 360,
      backgroundColor: 'unset',
      background: `url(${background}) no-repeat center`,
      backgroundSize: 'cover',
    },
    '& .faqInnerContainer': {
      '& .accordion__collapsable-text': {
        fontWeight: 400,
        color: palette.text.secondary,
      },
      '& .faq-link': {
        fontWeight: 'normal',
      },
      '& .accorion__item-title-container': {
        '& .MuiTypography-subtitle1': {
          fontSize: '20px',
          color: palette.text.primary,
        },
      },
      '& .collapseIcon, .expandIcon': {
        color: palette.verticalBar.used,
      },
    },
    '& .faq-contact-wrapper': {
      background: palette.background.light,
      '& .faq-contact-section': {
        '& .contact-img-wrapper': {
          width: '64px',
          maxWidth: '64px',
        },
      },
      'media (min-width: 900px)': {
        '& .contact-card-title': {
          fontSize: 48,
        },
        '& .contact-card-subtitle': {
          fontSize: 20,
        },
      },
      '& .provider-contact-details': {
        fontSize: 16,
      },
      '& .provider-contact-link': {
        fontSize: 20,
      },
    },

    /* Static Footer */
    '& .footerMainContainer': {
      '& .customMainContent': {
        lineHeight: '56px',
        maxWidth: 500
      },
      '@media(max-width: 900px)': {
        '& .customMainContent': {
          lineHeight: '40px',
          maxWidth: 500
        },
      }
    },

    /* Employee */
    '& .employerRootContainer': {
      background: palette.background.description,
      '& .home-hero-wrapper': {
        backgroundImage: `${employerBg}`,
        backgroundColor: 'unset',

        '@media(max-width: 900px)': {
          paddingTop: 0,
        },

        '& .hero-background': {
          objectFit: 'cover',
        },
        '& .hero-section': {
          display: 'flex',
          alignItems: 'center',
          maxHeight: '620px',
        },
        '& .home-hero-title': {
          maxWidth: '100%',
        },
        '& .home-hero-subtitle': {
          fontSize: 18,
        },
        '& .hero-link-button': {
          background: palette.verticalBar.used,
          color: palette.text.highlight,
        },
        '& .login-button': {
          borderColor: palette.verticalBar.used,
          background: palette.text.highlight,
          color: palette.verticalBar.used,
          '&:hover': {
            background: palette.text.highlight
          }
        },
        '@media screen and (max-width: 768px)': {
          '& .home-hero-title': {
            fontSize: '34px',
            color: palette.text.primary,
          },
        },
        '& .header-subtitle-divider': {
          marginTop: '0px !important',
          marginBottom: '32px !important',
        },
        '& .image': {
          display: 'block',
        },
        '@media(min-width: 1024px) and (max-width: 1366px)': {
          '& .image': {
            objectPosition: 'center',
            display: 'block',
          },
          '& .header-subtitle-divider': {
            marginTop: 12,
            marginBottom: 25,
          },
          '& .hero-title': {
            fontSize: 36,
          },
        },
        '& .customSubtitleItems': {
          fontSize: '18px !important',
        },
      },
      '& .hero-link-button, .login-button': {
        borderRadius: 4,
      },
      '& .hero-subtitle-divider-container': {
        '& .hero-subtitle-divider': {
          border: `2px solid ${palette.text.primary}`,
        },
      },
      '& .accordion__item-wrapper': {
        paddingTop: '10px',
        border: 'none !important',
        borderBottom: `1px solid ${palette.divider} !important`,
        borderRadius: '0 !important',
      },

      /* Subscription */
      '& .customSubscriptionWrapper': {
        background: palette.background.description,
        '& .section-header__subtitle, .customDisclaimerText': {
          color: palette.text.light,
        },
        '& .section': {
          '@media(min-width: 900px)': {
            padding: '64px',
          },
        },
        '& .subscription-getstarted-button': {
          background: palette.verticalBar.used,
          color: palette.text.highlight,
        },
        '& .customHeader': {
          maxWidth: '100%',
        },
        '& .customStaticCard': {
          height: 300,
          border: `1px solid ${palette.primary.main}`,
          borderRadius: '12px;',
        },
        '@media(min-width: 768px) and (max-width: 1180px)': {
          '& .customCardContainer': {
            maxWidth: '48%',
          },
        },
        '& .customValue': {
          color: palette.secondary.main,
        },
        '& .step-counter': {
          background: `${palette.background.tableAlternate} !important`
        },
        '& .customStartMembership': {
          borderRadius: 4,
          height: 70,
        },
        '& .MuiSwitch-thumb': {
          background: palette.text.highlight,
        },
        '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
          backgroundColor: palette.verticalBar.used,
        },
        '& .MuiSwitch-track': {
          border: 'none',
          background: palette.verticalBar.used,
        },
        '& .customCardContent': {
          paddingBottom: 0,
        },
      },

      /* How it works */
      '& .howitworks-section': {
        '& .howitworks-wrapper': {
          borderBottom: 'none',
        },
      },

      /* Metrics */
      '& .customMetricsRoot': {
        background: palette.secondary.main,
        '& #metrics-section': {
          '@media(min-width: 900px)': {
            padding: '48px 64px',
          },
          '& .left-section': {
            justifyContent: 'center',
            '& .title-box': {
              marginBottom: 36,
            }
          },
          '& .right-section': {
            justifyContent: 'center',
            '@media(max-width: 899.9px)': {
              padding: 0,
            },
            '& .title-box': {
              marginBottom: 36,
            }
          },
        },
        '& .section-header__title, .customMetricsDescription': {
          color: palette.text.highlight,
        },
        '& .metrics': {
          '& .section-header__title': {
            maxWidth: 600,
          },
          '& .metrics-getstarted-button': {
            background: palette.verticalBar.used,
            color: palette.text.highlight,
            borderRadius: 4,
            height: 56,
          },
        },
        '& .customDivider': {
          display: 'none',
        },
      },

      /* Comparison */
      '& .customComparisonContainer': {
        background: palette.verticalBar.used,

        '& .customImage': {
          marginBottom: 0,
        },
      },
      '& .customImage': {
        border: 'none',
      },
      '& .customSubtitle': {
        maxWidth: 400,
      },

      /* FAQ */
      '& .FAQSection': {
        background: palette.background.description,
        padding: '48px 24px',

        '@media(min-width: 900px)': {
          padding: '48px 64px',
        },
        '& .accordion__item-wrapper': {
          background: palette.background.description,
          '& .expandIcon, .collapseIcon': {
            color: palette.verticalBar.used,
          },
        },
        '& .accordion__collapsable-text': {
          color: palette.text.light,
        },
      },

      /* Hero Static Footer */
      '& .hero-static-bg': {
        backgroundColor: `${palette.secondary.main} !important`,
      },
      '& .hero-static-section': {
        '& .hero-static-title, .hero-static-subtitle': {
          color: `${palette.text.highlight} !important`,
        },
        '& .primary-static-button': {
          background: `${palette.verticalBar.used} !important`,
          color: `${palette.text.highlight} !important`,
          borderRadius: 4,
        },
      },
      '& .customBar': {
        color: palette.text.highlight,
      },
      '& .bar_accordion__item-wrapper': {
        padding: 0,
      },
    },

    /* Dashboard */
    '& .benefits-done-wrappper': {
      background: palette.primary.light,
    },
    '& .benefits-done-icon': {
      fill: palette.primary.main,
    },

    /* Stepper - styles */
    '& .customStepperRoot': {
      '& .stepperIconRoot': {
        borderColor: palette.text.primary,
        '& .stepperIconText': {
          fill: palette.text.primary
        },
      },
      '& .stepperIconCompleted': {
        color: palette.text.primary
      },
      '& .stepLabelActive': {
        color: palette.text.secondary
      },
      '& .stepperIconActive': {
        color: `${palette.text.primary} !important`,
        '& .stepperIconText': {
          fill: `${palette.text.highlight} !important`
        },
      },
      '& .MuiStepConnector-lineHorizontal': {
        borderColor: palette.text.primary,
      },
    },

    /* Create Subscriber - Checkout */
    '& .checkout-outer-container': {
      '& .payment-title-root': {
        marginTop: 0,
      },
      '& .promo-spinner': {
        backgroundColor: 'unset',
      },
      '& .icon-wrapper': {
        '& .groupIcon': {
          fill: palette.text.primary
        }
      },
      '& .customAddMembersLink': {
        color: palette.text.secondary
      },
      '& .cancelPolicyLink, & .termsLink': {
        color: palette.text.secondary
      },
    },

    /*Subscription Success */
    '& .success-wrapper': {
      maxWidth: '470px',
    },

    /*loader style */
    '& .plans-loader': {
      color: palette.secondary.main,
    },
  },
};
